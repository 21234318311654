.card {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
  box-shadow: 0 8px 20px -8px #000;
  overflow: hidden;
}

.card:hover .card-text {
  color: #E7E70F;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.95;
}

.card:hover .card-background {
  opacity: 0.8;
  transform: scale(1.1);
}

.card-background {
  width: 100%;
  transition: all .2s ease-in-out;
}

.card-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #B6B6B6;
  background-color: #0D0E13;
  opacity: 0.8;
  z-index: 2;
}

.card-title {
  padding: 1rem 0;
  width: 100%;
  font-size: 12px;
  text-align: center;
}
