.gallery-item {
  margin: 1rem;
  width: 220px;
  box-shadow: 0 8px 20px -8px #000;
  opacity: 0.9;
}

.gallery-item:hover {
  color: #E7E70F;
  cursor: pointer;
  box-shadow: 0 8px 20px -10px #36363B;
  opacity: 1;
}

.gallery-item:hover .gallery-item__image {
  transform: scale(1.05);
}

.gallery-item__image {
  width: 100%;
  transition: all .2s ease-in-out;
}

@media(max-width: 447px) {
  .gallery-item {
    opacity: 0.95;
  }

  .gallery-item:hover {
    box-shadow: 0 8px 20px -8px #000;
  }

  .gallery-item:hover .gallery-item__image {
    transform: none;
  }
}
