.image-container {
  margin: 10px auto;
}

.image-display {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.image-caption {
  margin-top: 5px;
  font-size: 9px;
  font-style: italic;
  text-align: center;
}
