.button {
  margin: 0 auto;
  padding: 10px;
  min-width: 100px;
  width: fit-content;
  color: #B6B6B6;
  background: transparent;
  border-bottom: 1px solid #B9B9B9;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.button:hover {
  color: #E7E70F;
  border-color: #F9F9F9;
}

@media (max-width: 1127px) {
  .button {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .button {
    font-size: 12px;
  }
}

@media (max-width: 447px) {
  .button {
    font-size: 11px;
  }
}
