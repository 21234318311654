.modal-body {
  padding: 5px;
  text-align: center;
}

.modal-body > img {
  width: 100%;
}

.modal-body, .modal-footer {
  background-color: #0D0E13;
}

.modal-footer {
  padding: 5px;
  border: none;
}
