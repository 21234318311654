.banner-textbox {
  position: absolute;
  top: 38%;
  width: 100%;
  text-align: center;
  cursor: default;
  animation: fadeInFromNone 1.5s ease-in forwards;
}

.banner-slogan {
  margin: 25px 10px;
  animation: fadeInFromNone 1s ease-in forwards;
  opacity: 0;
}

.banner-slogan_announcment {
  margin: 25px 10px;
  font-size: 19px;
  animation: fadeInFromNone 1s ease-in forwards;
  opacity: 0;
}

.b-honour {
  animation-delay: 1.5s;
}

.b-discipline {
  animation-delay: 2.5s;
}

.b-respect {
  animation-delay: 3.5s;
}

@media(max-height: 550px) {
  .banner-textbox {
    top: 28%;
  }
}

.home__map {
  height: 50vh;
}
