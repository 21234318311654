.footer {
  margin: 0 auto;
  padding: 35px 60px 50px 60px;
  width: 100%;
  height: auto;
  background-color: #181919;
}

@media (max-width: 767px) {
  .footer {
    padding: 35px 30px 40px 30px;
  }
}

.footer-block {
  margin: 0 auto;
  padding-top: 6px;
  max-width: 1000px;
  min-width: 250px;
  border-top: 1px solid #B6B6B6;
  font-size: 9px;
}

.footer-block.end {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
  border: none;
  font-size: 12px;
}

.footer-nav-column {
  display: inline-block;
  padding-top: 6px;
  text-align: left;
  vertical-align: top;
  width: 25%;
  min-width: 200px;
}

.footer-nav-column li,
.footer-nav-column li > a {
  list-style: none outside none;
  color: #B6B6B6;
  cursor: pointer;
}

.footer-nav-column li:first-child,
.footer-nav-column li:first-child > a {
  color: #ECECEC;
  font-size: 10px;
}

.footer-nav-column li > a:hover,
.footer-nav-column li > a:focus {
  text-decoration: none;
}

.copyright {
  min-width: 200px;
  text-align: center;
}

.footer-alliance {
  margin-bottom: 25px;
  text-align: center;
}

.footer-alliance-img {
  margin: 7px;
  width: 20%;
}
