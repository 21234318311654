.navbar {
  margin: 0;
  background-color: #121415;
  border: none;
  border-radius: 0;
  box-shadow: 0 8px 6px -8px #000;
}

.navbar-default
.navbar-collapse {
  border-color: #B6B6B6;
  max-height: none;
}

/* Dropdown */
.navbar-default
.navbar-nav > .open > a {
  color: #B6B6B6;
  background-color: #121415;
}

.navbar-default
.navbar-nav > .open > a:focus,
.navbar-default
.navbar-nav > .open > a:hover {
  color: #E7E70F;
  background-color: #121415;
}

.dropdown-menu {
  font-size: 10px;
  background-color: #121415;
}

.dropdown-menu > li > a {
  color: #B6B6B6;
  background-color: #121415;
  box-shadow: none !important;
  border: none !important;
  outline: none;
}

.dropdown-menu > li > a:hover {
  color: #E7E70F;
  background-color: #121415;
}

.dropdown-menu > .active > a {
  color: #F9F9F9;
  background-color: #121415;
}

.dropdown-menu > .active > a:hover {
  color: #E7E70F;
  background-color: #121415;
}

@media (max-width: 1024px) {
  /* BOOTSTRAP3 extend mobile menu resolution support */
  .navbar-header {
      float: none;
  }
  .navbar-left,.navbar-right {
      float: none !important;
      margin: 0;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .collapse.in{
      display:block !important;
  }
  /*END*/

  .navbar-default .navbar-nav .open .dropdown-menu>li>a {
    color: #B6B6B6;
    text-align: center;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus {
    color: #E7E70F;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>.active>a,
  .navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus {
    color: #F9F9F9;
    background-color: #121415;
  }
  .navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover {
    color: #E7E70F;
    background-color: #121415;
  }
}

/* Toggle Menu */
.navbar-default
.navbar-toggle,
.navbar-default
.navbar-toggle:hover,
.navbar-default
.navbar-toggle:focus {
  border: none;
  background-color: #121415;
}

.navbar-default
.navbar-toggle > .icon-bar {
  background-color: #B6B6B6;
}

.navbar-default
.navbar-toggle:hover > .icon-bar {
  background-color: #E7E70F;
}

/* Navbar brand/title */
.navbar-brand {
  display: flex;
  line-height: 15px;
  cursor: pointer;
}

.brand-logo {
  margin-top: -12px;
  margin-right: 1rem;
  width: 45px;
  height: 45px;
  background-image: url('../../images/goshin_logo_no-text_white.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.brand-title {
  letter-spacing: 5px;
}

.brand-subtitle {
  text-align: center;
  font-size: 6px;
  letter-spacing: 8px;
}

.navbar-default
.navbar-brand {
  color: #ECECEC;
}

.navbar-default
.navbar-brand:hover {
  color: #E7E70F;
}

/* Navbar links/items */
.navbar-default
.navbar-nav > li > a {
  text-align: center;
  color: #B6B6B6;
  font-size: 10px;
}

.navbar-default
.navbar-nav > li > a:hover,
.navbar-default
.navbar-nav > li > a:focus {
  color: #E7E70F;
}

/* Navbar link/item when active */
.navbar-default
.navbar-nav > .active > a,
.navbar-default
.navbar-nav > .active > a:focus  {
  color: #F9F9F9;
  background-color: #121415;
}

.navbar-default
.navbar-nav > .active > a:hover {
  color: #E7E70F;
  background-color: #121415;
}

/* Social links */
.social-links {
  padding: 1px;
  vertical-align: middle;
}
