html {
    overflow-y:scroll;
}

body {
  margin: 0;
  padding: 50px 0 0 0;
  font-family: Verdana, Geneva, sans-serif;
  color: #ECECEC;
  background-color: #171818;
  letter-spacing: 1.5px;
  background-size: 400px 400px;
  animation: fadeInFromNone 250ms ease-in forwards;
  user-select: none;
}

ul, li, p, h1, h2, h3, h4, div {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  pointer-events: none;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.75rem;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #1F1F26;
}

::-webkit-scrollbar-thumb:hover {
    background: #C7C706;
}
