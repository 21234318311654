/* Global overrides need to be in their own CSS file */
.nav-tabs {
  margin: 0 auto;
  max-width: 1000px;
}

.nav-tabs > li > a {
  margin-right: 1px;
  padding: 5px 10px;
  width: 145px;
  color: #B6B6B6;
  text-align: center;
  background-color: transparent;
  box-shadow: none !important;
  border: none !important;
  outline: none;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #E7E70F;
  background-color: transparent;
  border: none;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus {
  color: #F9F9F9;
}

.nav-tabs > li > a:hover {
  color: #E7E70F;
  background-color: transparent;
  border: none;
  box-shadow: 0;
}

@media (max-width: 447px) {
  .nav-tabs {
    padding: 0 calc(50% - 70px);
  }
}

.tab-content {
  padding: 1rem;
}

.section-block > p {
  padding: 10px;
}

.section-block > ul {
  margin: 0 35px;
}
