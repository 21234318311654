.schedule {
  display: flex;
  justify-content: space-evenly;
}

.schedule-title {
  margin: 2rem auto 0 auto;
  width: 90%;
  text-align: center;
}

.schedule-subtitle {
  margin: 0 1rem;
  padding: 1rem;
  text-align: center;
  border-bottom: #F9F9F9 1px solid;
  user-select: text;
}

@media(max-width: 447px) {
  #map {
    height: 300px;
  }

  .schedule {
    flex-direction: column;
    align-items: center;
  }

  .schedule-title {
    margin: 2rem 1rem 0 2rem;
    text-align: center;
  }
}
