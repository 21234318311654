.logo {
  margin: auto;
  text-align: center;
}

.logo-m {
  width: 160px;
}

.logo-l {
  width: 210px;
}

.title-m {
  letter-spacing: 8px;
}

.title-l {
  letter-spacing: 10px;
}

.subtitle-m {
  font-size: 9px;
  letter-spacing: 11px;
}

.subtitle-l {
  font-size: 11px;
  letter-spacing: 17px;
}
