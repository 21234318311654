.banner {
  position: relative;
  margin-top: -50px;
  background-color: #161A24;
  box-shadow: 0 8px 20px -8px #000;
}

.banner-image {
    width: 100%;
    height: 360px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: fadeInFromNone80 4s ease-out;
    opacity: 0.8;
}

.banner-image__viewport {
  height: 100vh;
}

.banner__title {
  position: absolute;
  top: 55%;
  left: 50%;
  margin: 0 -50% 0 0;
  transform: translate(-50%, -50%);
  letter-spacing: 30px;
  cursor: default;
}

.scroll-icon {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -175%);
  font-size: 4em;
  color: #F9F9F9;
  animation: fadeInOut 5s infinite;
}

.scroll-icon__text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -250%);
  font-size: 10px;
  color: #B6B6B6;
  animation: fadeInOut 5s infinite;
}

@media(max-width: 447px) {
  .banner__title {
    letter-spacing: 18px;
  }

  .scroll-icon {
    font-size: 3em;
  }
}
