.event-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 1rem;
  padding: .5rem;
  width: 300px;
  height: 150px;
  background: #19191D;
  box-shadow: 0 8px 20px -8px #000;
  color: inherit;
  overflow: hidden;
}

.event-card.download:hover {
  cursor: pointer;
}

.event-card:hover,
.event-card:focus {
  text-decoration: none;
}

.event-card:hover .event__icon-link {
  color: #ECECEC;
  animation: fadeOutIn 2s infinite;
}

.event-card:focus .event-date,
.event-card:hover .event-date,
.event-card:focus .event__icon-link,
.event__icon-link:focus,
.event-card:hover .event__icon-background,
.event-card:focus .event__icon-background {
  color: #ECECEC;
  animation: none;
}

.event-card:hover .event-title,
.event-card:focus .event-title {
  color: #E7E70F;
  text-decoration: none;
}

.event-date {
  padding: .75em;
  font-size: 12px;
}

.event-title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 280px;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.event__icon-link {
  position: absolute;
  top: 12px;
  right: 12px;
}

.event__icon-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
}

@media(max-width: 447px) {
  .event-card {
    width: auto;
  }
}
