.section-block {
  margin: 0 auto;
  padding: 20px 0;
  max-width: 1000px;
  min-width: 250px;
}

.section-header {
  margin-bottom: 10px;
  border-bottom: 1px solid #B6B6B6;
}

.section-header > h2 {
  display: table;
  margin: 0;
  padding: 0 6px;
  width: auto;
  font-size: 20px;
  color: #171C27;
  background-color: #ECECEC;
}

.section-body > p {
  margin-top: 7px;
}

.section-body > ul {
  margin: 7px;
}

.section-body > ul > li {
  margin: 2px 0 0 14px;
}
