.banner-alert {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 1001;
}

.banner-alert-red {
  background-color: #BD2C2C;
}

.banner-alert-green {
  background-color: #168c31;
}

.banner-alert_text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.banner-alert_icon {
  margin-right: 7px;
  font-size: 1.3em;
}
