.achievement {
  position: relative;
  padding: 1rem;
  width: fit-content;
}

.achievement.selectable:hover {
  cursor: pointer;
}

.achievement.selectable:hover .achievement-text,
.achievement.selectable:focus .achievement-text {
  color: #E7E70F;
}

.achievement.selectable:hover .achievement-link-icon,
.achievement.selectable:focus .achievement-link-icon {
  animation: fadeOutIn 2s infinite;
}

.achievement-text {
  padding: 0 5px;
}

.achievement-link-icon {
  position: absolute;
  top: 8px;
  right: -4px;
  font-size: .75em;
}
