@keyframes fadeInFromNone {
    0% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone80 {
    0% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 0.8;
    }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

@keyframes fadeOutIn {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}
