.unknown-bg {
  position: relative;
  width: 100%;
  height: 500px;
  background-color: #3A3E49;
}

.unknown-block {
  position: absolute;
  top: 130px;
  left: 50%;
  margin-left: -106px;
  text-align: center;
}

.unknown-title {
  margin-bottom: 25px;
  font-size: 7rem;
}

.unknown-text {
  margin-top: 25px;
  font-size: 20px;
}
