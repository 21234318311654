.instructor-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin: 2rem 1rem;
  padding: 1rem;
  background-color: #19191D;
  box-shadow: 0 8px 20px -8px #000;
}

.instructor-identity {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instructor-photo {
  padding-bottom: 1rem;
  max-width: 200px;
  border-bottom: #B6B6B6 1px solid;
}

.instructor-name {
  margin: 1rem;
  width: 220px;
  text-align: center;
}

.instructor-achievements {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.instructor-bio {
  width: 100%;
}

.bio {
  margin: 1rem;
  max-height: 0;
  transition: max-height 500ms linear;
  overflow: hidden;
}

.bio.show {
  max-height: 2000px;
}

.expand-bio-button {
  position: absolute;
  bottom: -15px;
  left: 50%;
  margin: 0 auto;
  width: 40px;
  color: #B6B6B6;
  text-align: center;
  cursor: pointer;
  z-index: 1;
}

.expand-bio-button:hover {
  color: #E7E70F;
}

.expand-bio-button.selected {
  color: #E7E70F;
  transform: rotate(180deg);
}

@media (max-width: 767px) {
  .instructor-achievements {
    width: 90%;
  }

  .achievement {
    padding: 1rem 0;
  }
}
