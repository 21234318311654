.card-container {
  display: flex;
  overflow-x: auto;
}

.card-container.wrap {
  flex-wrap: wrap;
}

.card-container.empty {
  justify-content: center;
}

@media(max-width: 447px) {
  .card-container {
    flex-direction: column;
  }
}

.card-container-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  height: 150px;
  text-align: center;
}
