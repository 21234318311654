.find-dojo__map {
  height: 500px;
}

.map-section {
  padding-top: 0;
}

.schedule-col {
  width: 25%;
  text-align: center;
}

.schedule-col.desktop:first-child {
  text-align: right;
}

.schedule-col.desktop:nth-child(3) {
  text-align: left;
}

.schedule-col.mobile {
  display: none;
  width: 80%;
}

.schedule-col > ul > li {
  font-size: 12px;
  list-style: none;
}

.schedule-col > h5 {
  padding-top: 1rem;
  font-weight: bold;
}

.schedule-col.mobile > h5 {
  margin-top: 10px;
}

.schedule-col.mobile > ul > li {
  display: flex;
  justify-content: space-between;
}

@media(max-width: 447px) {
  .find-dojo__map {
    height: 300px;
  }

  .schedule-col {
    display: none;
  }

  .schedule-col.mobile {
    display: block;
    text-align: left;
  }
}
