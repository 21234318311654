.affiliate-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 1rem;
  padding: 1rem;
  width: 275px;
  height: 150px;
  background-color: #19191D;
  box-shadow: 0 8px 20px -8px #000;
  overflow: hidden;
  z-index: 1;
}

.affiliate-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60%;
  opacity: 0.05;
  z-index: 2;
}

.affiliate-header {
  padding: 0 1rem 1rem 1rem;
  border-bottom: #F9F9F9 1px solid;
  z-index: 3;
}

.affiliate-header > h5 {
  margin-bottom: 1px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.affiliate-subheader {
  height: 9px;
  font-size: 9px;
  font-style: italic;
}

.affiliate-info {
  overflow: hidden;
  z-index: 3;
}

.affiliate-detail {
  font-size: 11px;
}

.affiliate-link {
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
